<template>
  <div>
    <div class="opt-bar flex"> 
      <div class="opt-left">     
        <el-input size="small"
            @keyup.enter.native="onQuery"
            class="searcher-input"
            placeholder="关键词"
            clearable
            @clear="onQuery"
            v-model="query.skey">
            <i slot="prefix" class="el-input__icon el-icon-search"></i>
          </el-input>
        <el-button  @click="onQuery" type="primary" size="mini"><i class="iconfont iconsearch"></i></el-button>
        <el-button @click="onBtnDeleteClick" type="danger" size="mini"><i class="iconfont iconclose"></i>删除</el-button>
      </div>
      <div class="opt-right">
        <el-tag>共{{list.length}}个草稿</el-tag>        
      </div>
    </div>
    <split-pane split="vertical" class="pane" :default-percent='75' :resizerThickness="5" @resize="paneResizeVertical">
      <template slot="paneL">
        <el-table :data="list" element-loading-text="Loading..."
          size="small"
          border fit highlight-current-row
          header-row-class-name="tableHeader"          
          :height="tableHeight"
          :row-class-name="tableRowClass"
          row-key="draft_id"
          default-expand-all
          @current-change="onCurrentChange"
        >     
          <el-table-column label="模板ID" width="90" align="center">
            <template slot-scope="scope">
              {{ scope.row.template_id }}
            </template>
          </el-table-column>
           <el-table-column label="源名称" width="130" align="center">
            <template slot-scope="scope">          
              <span>{{ scope.row.source_miniprogram }}</span>          
            </template>
          </el-table-column>
          <el-table-column label="版本" width="80" align="center">
            <template slot-scope="scope">          
              <span>{{ scope.row.user_version }}</span>          
            </template>
          </el-table-column>         
          <el-table-column label="源APPID" width="150" align="center">
            <template slot-scope="scope">          
              <span>{{ scope.row.source_miniprogram_appid }}</span>          
            </template>
          </el-table-column>
          <el-table-column label="开发者" width="100" align="center">
            <template slot-scope="scope">          
              <span>{{ scope.row.developer }}</span>          
            </template>
          </el-table-column>
          <el-table-column label="描述"  align="left">
            <template slot-scope="scope">
              {{ scope.row.user_desc}}
            </template>
          </el-table-column>
          <el-table-column label="上传时间" width="135" align="left">
            <template slot-scope="scope">
              {{ transStamp(scope.row.create_time)}}
            </template>
          </el-table-column>
          <el-table-column label="类型" width="110" align="left">
            <template slot-scope="scope">
              {{ scope.row.template_type == 0 ? '普通模板' : '标准模板'}}
            </template>
          </el-table-column>
          <!-- <el-table-column label="界面截图" width="160" align="center">
            <template slot-scope="scope">
              {{ scope.row.img}}
            </template>
          </el-table-column>             -->
          <!-- <el-table-column label="操作" width="160" align="center">
            <template slot-scope="scope">
              <div class="row-opt">
                <el-tooltip class="item" effect="dark" content="添加到模板库" placement="bottom">
                  <icon-btn @click="handleUpload(scope.$index, scope.row)"
                  icon="iconnew"
                  type="success"
                  ></icon-btn>
                </el-tooltip>
                <el-tooltip class="item" effect="dark" content="上传界面截图" placement="bottom">
                  <el-upload
                    class="upload-demo"
                    action="https://jsonplaceholder.typicode.com/posts/"
                    :on-preview="handlePreview"                           
                    :limit="1"
                    :on-exceed="handleExceed"
                    :file-list="fileList">
                    <icon-btn @click="handleUpload(scope.$index, scope.row)"
                    icon="iconicon-test"
                    type="warning"
                    ></icon-btn>
                  </el-upload>
                </el-tooltip>
                <el-tooltip class="item" effect="dark" content="上传界面截图" placement="bottom">
                  <icon-btn @click="handleDelete(scope.$index, scope.row)"
                  icon="icondel1"
                  type="error"
                  ></icon-btn>
                </el-tooltip>
              </div>
            </template>
          </el-table-column> -->
        </el-table>
      </template>
      <template slot="paneR">      
        <div class="pane-right" v-if="current">
          <el-tabs v-model="curTab" @tab-click="onTabClick">
            <el-tab-pane label="界面" name="界面">
              <div class="tpl-img" v-if="current.tpl && current.tpl.tpl_img">
                <img :src="current.tpl.img">
              </div>
              <div class="tab-btns">
                <el-upload
                    class="upload-demo"
                    action="https://jsonplaceholder.typicode.com/posts/"
                    :on-preview="handlePreview"                           
                    :limit="1"
                    :on-exceed="handleExceed"
                    :file-list="fileList">
                    <el-button type="primary">上传模板图片</el-button>
                  </el-upload>
              </div>
            </el-tab-pane>
            <el-tab-pane label="配置" name="配置">配置管理</el-tab-pane>
            <el-tab-pane label="引用" name="引用">角色管理</el-tab-pane>            
          </el-tabs>
        </div>
      </template>
    </split-pane>
    <div v-if="refreshFlag"></div>
  </div>
</template>


<script>
import splitPane from 'vue-splitpane'
import IconBtn from '../../components/btns/IconBtn.vue';
export default {
  components: {   IconBtn, splitPane },
  data(){
    return {
      tableHeight: 560,
      refreshFlag: false,
      query: {skey: ''},
      list: [],
      fileList: [],//为图片上传
      current: false,
      curTab: '界面',
    } //--return
  },//--data
  created(){
    this.resize();
    window.onresize = this.resize();
    this.onQuery();
  },
  methods: {
    onTabClick(tab, e) {
      console.log(tab.name)
    },
    resize() {
      var clientHeight = document.documentElement.clientHeight
      this.tableHeight = clientHeight - 140
    },
    onCurrentChange(curRow, oldRow) {
      this.current = curRow;
      if (oldRow == null || curRow.id != oldRow.id) {//去调取该行的本地设置
        if (!curRow.tpl) { //还没有获取本地信息
          var pm = {
            template_id: this.current.template_id
          }
          this.post('/api/waxlib/loadWxTpl', pm, res=>{
            if (res.code == 0) {
              this.current.tpl = res.data
            } else {
              this.$notify.error(res.msg)
            }
          })
        }
      }
    },
    onBtnSyncClick() {

    },
    handleUpload(idx, row) {

    },
    onBtnDeleteClick(idx, row) {

    },
    mouseLeave(row) {
      this.current = false
    },
    mouseEnter(row) {
      this.current = row;
    },
    onQuery() {
      var pm = {
        template_type: 0
      }
      this.post('/api/waxlib/tplsList', pm, res=>{
        if (res.errcode == 0) {
          this.list = res.template_list
          if (this.list.length> 0) {
            this.current = this.list[0]
          }
          // console.log(this.list)
        } else {
          this.$notify.error('获取列表失败~' + res.errmsg);
        }
      })
    },
    onPageSizeChange(e) {
      this.page.pageSize = e;
      this.onQueryPage(1)
    },
    tableRowClass({row, rowIndex}) {
      if (row.upid == 0) {
        return 'success-row'
      }
      if (row.stat === 0) {
        return "error-row"
      } else {
        if (rowIndex % 2 === 0) {
          return '';
        } else {
          return 'second-row'
        }
      }
    },
    transStamp(dt) {
      var sdt = dt * 1000      
      return this.util.formatDate(new Date(sdt))
    },
    paneResizeVertical(e) {

    },
    handleExceed(files, fileList) {

    },
    handlePreview(file) {

    },
  }//-methods
};
</script>

<style scoped lang="scss">
.row-opt {
  display: flex;
  max-width: 160px;  
  text-align: center;
  justify-content: space-around;
  .item {
    flex: 0 0 auto;
  }
  .upload-demo {
    display: inline;    
  }
}
.pane-right {
  margin: 0.5em;
}
</style>